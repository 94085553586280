import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'

export function listenAccountMenuEvents (event) {
  const eventDetails = {
    [eventTypes.accountMenuEvents.MY_ACCOUNT_CLICKED]: {
      description: 'Click on My Sunweb',
      result: 'redirects to the login page'
    },
    [eventTypes.accountMenuEvents.CREATE_ACCOUNT_CLICKED]: {
      description: 'Click on Create Account',
      result: 'redirects to the account creation page'
    },
    [eventTypes.accountMenuEvents.MY_BOOKINGS_CLICKED]: {
      description: 'Click on My Bookings',
      result: "redirects to 'My Sunweb' page"
    },
    [eventTypes.accountMenuEvents.FAVORITES_CLICKED]: {
      description: 'Click on Favorites',
      result: 'redirects to the favorites page'
    }
  }

  Object.entries(eventDetails).forEach(([eventType, { description, result }]) => {
    event.on(eventType, () => pushEvent(description, result))
  })
}

function pushEvent (description, result) {
  pusher.pushDataObject({
    event: 'manageAccount',
    description,
    element: 'button',
    result,
    interaction: true
  })
}
