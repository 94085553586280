import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getProfileData } from '../account-data/account-data'
import registeredEvents from '../../../js/helpers/registered-events'
import { accountMenuEvents } from '../../../js/document/event-types'
const EventEmitter = require('eventemitter3')

const widgetApi = 'w-account-menu'

const classNames = {
  isOpen: 'is-open',
  isHidden: 'is-hidden',
  showAvatar: `${widgetApi}__button--show-avatar`
}

const attr = {
  track: 'data-track'
}

const widgetQueries = {
  trigger: `[data-${widgetApi}__trigger]`,
  dropdown: `[data-${widgetApi}__dropdown]`,
  loginItem: `[data-${widgetApi}__login-item]`,
  logoutItem: `[data-${widgetApi}__logout-item]`,
  loginButton: `[data-${widgetApi}__login-btn]`,
  createAccountLink: `[data-${widgetApi}__create-account-link]`,
  bookingsOverviewLink: `[data-${widgetApi}__bookings-overview-link]`,
  favoritesButton: `[data-${widgetApi}__favorites-btn]`

}

export default class AccountMenu {
  constructor (element) {
    if (!element) return
    this.element = element
    this._getHtmlElements()
    this._attachEvents()
    this._renderDropdown()
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  _getHtmlElements () {
    this.triggers = this.element.querySelectorAll(widgetQueries.trigger)
    this.dropdown = this.element.querySelector(widgetQueries.dropdown)
    this.loginItems = this.element.querySelectorAll(widgetQueries.loginItem)
    this.logoutItems = this.element.querySelectorAll(widgetQueries.logoutItem)
    this.loginButton = this.element.querySelector(widgetQueries.loginButton)
    this.createAccountLink = this.element.querySelector(widgetQueries.createAccountLink)
    this.bookingsOverviewLink = this.element.querySelector(widgetQueries.bookingsOverviewLink)
    this.favoritesButton = this.element.querySelector(widgetQueries.favoritesButton)
  }

  _attachEvents () {
    this.triggers.forEach(trigger => trigger.addEventListener('click', this._toggleDropdown.bind(this)))

    document.addEventListener('click', (event) => {
      const isClickInsideTrigger = Array.from(this.triggers).some(trigger => trigger.contains(event.target))
      if (!this.dropdown.contains(event.target) && !isClickInsideTrigger) {
        this._closeDropdown()
      }
    })

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this._closeDropdown()
      }
    })

    const eventMappings = {
      [widgetQueries.loginButton]: accountMenuEvents.MY_ACCOUNT_CLICKED,
      [widgetQueries.createAccountLink]: accountMenuEvents.CREATE_ACCOUNT_CLICKED,
      [widgetQueries.bookingsOverviewLink]: accountMenuEvents.MY_BOOKINGS_CLICKED,
      [widgetQueries.favoritesButton]: accountMenuEvents.FAVORITES_CLICKED
    }

    Object.entries(eventMappings).forEach(([selector, eventType]) => {
      const element = this.element.querySelector(selector)
      if (element) {
        element.addEventListener('click', () => this.events.emit(eventType))
      }
    })
  }

  _toggleDropdown () {
    if (this.element.classList.contains(classNames.isOpen)) {
      this._closeDropdown()
    } else {
      this.element.classList.add(classNames.isOpen)
      this.element.ariaExpanded = true
    }
  }

  _closeDropdown () {
    this.element.classList.remove(classNames.isOpen)
    this.element.ariaExpanded = false
  }

  _renderDropdown () {
    const profileData = getProfileData()

    if (profileData && profileData.isUserLoggedIn) {
      this.loginItems.forEach(item => item.classList.remove(classNames.isHidden))
      this.logoutItems.forEach(item => item.classList.add(classNames.isHidden))
      this._renderAvatar(profileData)
    } else {
      this.loginItems.forEach(item => item.classList.add(classNames.isHidden))
      this.logoutItems.forEach(item => item.classList.remove(classNames.isHidden))
    }
  }

  _renderAvatar (profileData) {
    if (profileData.profileName) {
      const initial = profileData.profileName.charAt(0)
      this.triggers.forEach(trigger => {
        trigger.setAttribute('avatar-content', initial)
        trigger.classList.add(classNames.showAvatar)
      })
    }
  }
}

registerWidget(AccountMenu, widgetApi)
